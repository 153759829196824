$color-primary: #41D5FB;
$color-primary-2: red;
$color-bg-button: #F7F8FA;
$color-primary-toolbar: #252525;
$color-bg: #fafafa;
$color-gray: #eeeeee;
$color-hover: rgb(216, 216, 216);
$color-bg-cutting: #f1f1f1;

$color-btn-select:#f0f0f1; // #AAB2BF - opacity: 0.1;

$opacity-primary: .9;
$padding-primary: 8px;

$radius-primary: 4px;
$radius-panel: 15px;
$radius-button: 10px;

$border: 1px solid #D9D9D9;
$border-radius: 15px solid #D9D9D9;


$height-toolbar: 38px;
$offset-top: calc(38px + 5px);
$offset-bottom: 16px;

$icon-size-small: 14px;
$font-size-span-small: 14px;

$dim-button-panel: 45px;

@mixin button-panel {
    @include flex-col();
    justify-content: center;
    align-items: center;
    margin-bottom: $offset-bottom;
    border-radius: $radius-button;
    background-color: $color-bg-button;

    width: $dim-button-panel;
    height: $dim-button-panel;
}

@mixin mat-icon-small {
    font-size: $icon-size-small;
    width: $icon-size-small;
    height: $icon-size-small;
}

$icon-small: 16px;

@mixin icon-small {
    height: $icon-small;
    width: $icon-small;
    font-size: $icon-small;
    cursor: pointer;
}

@mixin flex-col {
    display: flex;
    flex-direction: column;
}

@mixin flex-row {
    display: flex;
    flex-direction: row;
}
@mixin flex-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rotate-90 {
    transform: rotate(90deg);
}