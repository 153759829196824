/* Set default font-family */
#editor-container {
  font-family: "arial";
  font-size: 15px;
  height: 200px;
}
#toolbar-container .ql-font {
  width: 145px;
}
#toolbar-container .ql-color {
  .ql-picker-label {
    display: flex;
    margin-top: 1px;
  }
}
#toolbar-container .ql-size {
  width: 60px;
  .ql-picker-options {
    border-color: #ccc;
    overflow: auto;
    max-height: 205px;
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(172, 172, 172);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }
    &::placeholder {
      color: inherit;
      opacity: 0.5;
    }
    ::-webkit-input-placeholder {
      color: inherit;
      opacity: 0.5;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: inherit;
      opacity: 0.5;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: inherit;
      opacity: 0.5;
    }
    :-ms-input-placeholder {
      color: inherit;
      opacity: 0.5;
    }
  }
}
/* Set dropdown font-families */
#toolbar-container .ql-font span[data-label="Arial"]::before {
  font-family: "arial";
}
#toolbar-container .ql-font span[data-label="Courier New"]::before {
  font-family: "couriernew";
}
#toolbar-container .ql-font span[data-label="Palatino"]::before {
  font-family: "palatino";
}
#toolbar-container .ql-font span[data-label="Times New Roman"]::before {
  font-family: "timesnewroman";
}
#toolbar-container .ql-font span[data-label="Trebuchet"]::before {
  font-family: "trebuchet";
}
#toolbar-container .ql-font span[data-label="Verdana"]::before {
  font-family: "verdana";
}

/* Set content font-families */
.ql-font-arial {
  font-family: "arial";
}
.ql-font-couriernew {
  font-family: "couriernew";
}
.ql-font-palatino {
  font-family: "palatino";
}
.ql-font-timesnewroman {
  font-family: "timesnewroman";
}
.ql-font-trebuchet {
  font-family: "trebuchet";
}
.ql-font-verdana {
  font-family: "verdana";
}
/* Set content font-color */
.ql-color .ql-picker-options [data-value="custom-color"] {
  background: none !important;
  width: 100% !important;
  height: 20px !important;
  text-align: center;
}
.ql-color .ql-picker-options [data-value="custom-color"]:before {
  content: "Custom Color";
}
.ql-color .ql-picker-options [data-value="custom-color"]:hover {
  border-color: transparent !important;
}
/* We do not set Aref Ruqaa since it is the default font */
