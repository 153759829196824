@import "../custom-theme/define-color.scss";

.backdrop-default-custom {
  background-color: transparent;
}
.panel-class-default-custom {
  background-color: transparent;
  .mat-mdc-dialog-container,
  .mat-mdc-dialog-container .mdc-dialog__container,
  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0px;
    border-radius: 5px;
  }
}
//dialog context menu
.backdrop-class-custom {
  pointer-events: none;
  background-color: transparent;
}
.backdrop-class-modal-form-custom {
  background-color: transparent;
}
.dialog-access-hyperlink {
  .mat-mdc-dialog-container,
  .mat-mdc-dialog-container .mdc-dialog__container,
  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 22px;
    box-shadow: 0 0 14px 3px hsla(0, 0%, 59.2%, 0.85);
  }
}

.container-custom-dialog {
  background-color: transparent;
  .mat-mdc-dialog-container,
  .mat-mdc-dialog-container .mdc-dialog__container,
  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0px;
    border-radius: 10px;
  }
}
.container-custom-dialog-style {
  background-color: transparent;
  .mat-mdc-dialog-container,
  .mat-mdc-dialog-container .mdc-dialog__container,
  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 24px;
    border-radius: 10px;
  }
}
.dialog-shortcuts-style {
  background-color: transparent;
  .mat-mdc-dialog-container,
  .mat-mdc-dialog-container .mdc-dialog__container,
  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0px;
    border-radius: 10px;
    opacity: 0.9;
    min-width: 780px;
  }
}

//mat badge center top panel
#center-top-panel .mat-badge-content {
  top: 0px !important;
}

//dialog select file
.app-select-dialog  {
  .mat-mdc-dialog-container,
  .mat-mdc-dialog-container .mdc-dialog__container,
  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0px;
    border-radius: 20px;
  }
}

//global scroll => hidden
.cdk-global-scrollblock {
  overflow-y: hidden;
}

//mat tab
#container-main-viewer-right-panel,
.container-markup-group-details {
  & > mat-tab-group {
    width: inherit;
    height: inherit;
    max-width: inherit;
    padding: 0px 10px;
    .mat-tab-label-container {
      .mat-tab-list {
        .mat-ink-bar {
          width: calc(100% / 2) !important;
        }
      }
    }
    .mat-tab-body-wrapper {
      padding: 10px 0px 10px 0 !important;
      flex: 1 1 auto;
    }
  }
}

#container-tree-views > mat-tab-group {
  width: inherit;
  height: inherit;
  max-width: inherit;
  .mat-tab-header {
    margin: 0 10px;
  }
  .mat-tab-body-wrapper {
    padding: 10px 0px 10px 0 !important;
    flex: 1 1 auto;
  }
  .mat-tab-label {
    min-width: calc(100% / 2) !important;
  }
  .mat-tab-label-container {
    .mat-tab-list {
      .mat-ink-bar {
        width: calc(100% / 2) !important;
      }
    }
  }
}

#container-content-panel > mat-tab-group {
  width: inherit;
  height: inherit;
  max-width: inherit;
  .mat-tab-header {
    margin: 0 10px;
  }
  .mat-tab-body-wrapper {
    padding: 10px 0px 10px 0 !important;
    flex: 1 1 auto;
  }
  .mat-tab-label {
    min-width: calc(100% / 2);
  }
  .mat-tab-label-container {
    .mat-tab-list {
      .mat-ink-bar {
        width: calc(100% / 2);
      }
    }
  }
}
.active > mat-tab-group {
  .mat-tab-label {
    min-width: calc(100% / 3) !important;
  }
  .mat-tab-label-container {
    .mat-tab-list {
      .mat-ink-bar {
        width: calc(100% / 3) !important;
      }
    }
  }
}
#container-view-panel > mat-tab-group {
  width: inherit;
  height: inherit;
  max-width: inherit;
  .mat-tab-header {
    margin: 0 10px;
  }
  .mat-tab-body-wrapper {
    padding: 10px 0px 10px 0 !important;
    flex: 1 1 auto;
  }
  .mat-tab-label {
    min-width: calc(100% / 2) !important;
  }
  .mat-tab-label-container {
    .mat-tab-list {
      .mat-ink-bar {
        width: calc(100% / 2) !important;
      }
    }
  }
}

.container-markup-group-details
  > mat-tab-group
  .mat-tab-label-container
  .mat-tab-list
  .mat-ink-bar {
  width: inherit !important;
}

.container-views {
  position: relative;
  display: grid;
  grid-template-rows: 1fr minmax(0px, auto);
  width: 100%;
  height: 100%;
  mat-tab-group {
    width: inherit;
    height: inherit;
    padding: 0px 10px;
    .mat-tab-label {
      min-width: calc(100% / 2) !important;
    }
    .mat-tab-label-container {
      .mat-tab-list {
        .mat-ink-bar {
          width: calc(100% / 2) !important;
        }
      }
    }
    .mat-tab-body-wrapper {
      padding: 0px 0px 10px 0 !important;
      flex: 1 1 auto;
      height: 100% !important;
    }
  }
}

//end

.dialog-container-without-padding {
  .mat-mdc-dialog-container,
  .mat-mdc-dialog-container .mdc-dialog__container,
  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0px;
  }
}

//dialog print
.print-dialog-container  {
  .mat-mdc-dialog-container,
  .mat-mdc-dialog-container .mdc-dialog__container,
  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0px;
  }
}
.back-drop-class {
  background-color: ghostwhite;
}

.water-mark-dialog-container {
  .mat-mdc-dialog-container,
  .mat-mdc-dialog-container .mdc-dialog__container,
  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0px;
  }
}

.setting-dialog-container {
  .mat-mdc-dialog-container,
  .mat-mdc-dialog-container .mdc-dialog__container,
  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0px;
  }
}

.custom-container-notify {
  background: $bg-color;
  color: $color-dark;
  font-weight: 800;
}

.custom-container-notify-copied {
  background: #51a56b;
  color: #0d1c36;
  font-weight: 800;
  min-width: 150px !important;
  padding: 10px 16px !important;
  min-height: 33px !important;
}

//dialog input edit text
.backdrop-input-edit {
  background-color: transparent;
}
.container-input-edit {
  background-color: transparent;
  .mat-mdc-dialog-container,
  .mat-mdc-dialog-container .mdc-dialog__container,
  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0px;
    border-radius: 10px;
    box-shadow: none !important;
    background: transparent;
  }
}

//dialog context menu edit
.container-contextmenu-edit,
.container-contextmenu-layer,
.container-markup-entity-menu {
  background-color: transparent;
  .mat-mdc-dialog-container,
  .mat-mdc-dialog-container .mdc-dialog__container,
  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0px;
    border-radius: 5px;
    overflow: inherit;
  }
  .mat-mdc-dialog-container .mat-mdc-dialog-content {
    padding: 0px !important;
  }
}

// Tab setting
#container-panel-setting {
  width: 360px;
  mat-tab-group {
    height: 100%;
    .mat-tab-label {
      min-width: auto !important;
      padding: 5px 5px;
    }
  }
}
// mat slider
.mat-slider:not(.mat-slider-disable).cdk-focused .mat-slider-thumb-label {
  border-radius: 4px !important;
}
.mat-slider-horizontal.cdk-focused .mat-slider-thumb-label {
  transform: rotate(0deg) !important;
}
.mat-slider-horizontal.cdk-focused .mat-slider-thumb-label-text {
  transform: rotate(0deg) !important;
}
.mat-slider-thumb-label {
  width: 30px !important;
  height: 30px !important;
}
.mat-slider-horizontal {
  .mat-slider-track-background {
    top: 1px;
  }
  .mat-slider-track-fill {
    height: 4px !important;
  }
}

//group-entity
.container-markup-group-entity .group-entity-title .entity-title {
  .mat-checkbox > label {
    margin-bottom: 2px !important;
  }
}

.container-custom-dialog-setting {
  background-color: transparent;
  transform: translate3d(0px, 0px, 0px);
  .mat-mdc-dialog-container,
  .mat-mdc-dialog-container .mdc-dialog__container,
  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0px;
    border-radius: 10px;
    overflow: inherit;
  }
  .mat-mdc-dialog-container .mat-mdc-dialog-content {
    padding: 0px !important;
    overflow: inherit;
  }
}

.container-custom-dialog-none-scroll {
  background-color: transparent;
  transform: translate3d(0px, 0px, 0px);
  .mat-mdc-dialog-container,
  .mat-mdc-dialog-container .mdc-dialog__container,
  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0px;
    border-radius: 10px;
    overflow: initial;
  }
  .mat-mdc-dialog-container .mat-mdc-dialog-content {
    padding: 0px !important;
  }
}

.container-custom-dialog-color {
  background-color: transparent;
  transform: translate3d(0px, 0px, 0px);
  .mat-mdc-dialog-container,
  .mat-mdc-dialog-container .mdc-dialog__container,
  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0px;
    border-radius: 10px;
    min-width: 270px !important;
    min-height: 540px !important;
  }
}

.container-custom-dialog-help {
  .mat-mdc-dialog-container,
  .mat-mdc-dialog-container .mdc-dialog__container,
  .mat-mdc-dialog-container .mdc-dialog__surface {
    min-width: 1200px;
  }
}
//badge menu
.container-menu .mat-badge-content {
  right: -5px;
  border: 1px solid #fff;
}

.mdc-form-field > label {
  margin-bottom: 0px;
}

.mat-mdc-dialog-container,
.mat-mdc-dialog-container .mdc-dialog__container,
.mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 24px;
}

.mat-mdc-unelevated-button .mat-mdc-button-touch-target {
  position: relative !important;
}

.view-item .mdc-button__label,
.container-custom-context-menu .mdc-button__label,
.container-contextmenu-edit .mdc-button__label,
.container-markup-entity .mdc-button__label,
.container-markup-entity-menu .mdc-button__label {
  width: 100%;
  text-align: left;
}

.container-option-custom
.mdc-button .mdc-button__label {
  width: 100%;
}

.mat-mdc-tab-body-wrapper {
  height: 100%;
}
