$color-primary: #0D1C36;
$color-accent: #56a97a;
$color-dark: #0D1C36;
$color-light: #ffffff;
$color-gray: #AAB2BF;
$color-gray-3: #F5F5F5;
$color-yellow: #ffff00;
$color-yellow-1: #ffc90e;
$color-primary-toolbar: #252525;
$border-color: #D9D9D9;
$bg-color: #FAFAFA;
$bg-color-content: #FFFFFF;
$color-denim: #8accff;
$bg-color-dialog: #7a7a7a;

$old-color-primary: #31629b;
$new-color-primary: #001370;
