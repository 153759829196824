
$bg-color-scroll: #AAB2BF;
$bg-color-scroll-2: #D9D9D9;
$border-radius-scroll: 4px;
$width-scroll: 5px;
$height-scroll: 5px;

/** Scrollable **/
.disable-scrollable {
  overflow: hidden !important;
}
.enable-scrollable {
  overflow: overlay !important;
  overflow: auto;
}

.custom-scrollbar {
    &::-webkit-scrollbar {
        width: $width-scroll;
        height: $height-scroll;
    }
    &::-webkit-scrollbar-thumb {
        background: $bg-color-scroll;
        border-radius: $border-radius-scroll;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 $border-radius-scroll $bg-color-scroll-2;
        border-radius: $border-radius-scroll;
    }
    scrollbar-color: $bg-color-scroll $bg-color-scroll-2;
    scrollbar-width: thin;
}
/** end scroll **/

.setting-picker-item color-picker{
  position : fixed;
  z-index: 100;
}

.color-picker .arrow.arrow-bottom {
  left: 160px !important;
}
.color-picker .arrow.arrow-top{
  left: 158px !important;
}

/** resize **/
@mixin resize-div-base($direction) {
  position: absolute;
  z-index: 999;
  background-color: transparent;
  &:hover {
    @if $direction == 'vertical' {
      cursor: ns-resize;
    }
    @if $direction == 'horizontal' {
      cursor: ew-resize;
    }
    background-color: $bg-color-scroll;
  }
}
@mixin resize-div-base-vertical($isTop) {
  left: 0;
  @if $isTop == 'true' {
    top: 0;
  }
  @if $isTop == 'false' {
    bottom: 0;
  }
  width: 100%;
  height: 8px;
  @include resize-div-base('vertical');
}
@mixin resize-div-base-horizontal($isLeft) {
  top: 0;
  @if $isLeft == 'true' {
    left: 0;
  }
  @if $isLeft == 'false' {
    right: 0;
  }
  width: 8px;
  height: 100%;
  @include resize-div-base('horizontal');
}

.resize-div-vertical-top {
  @include resize-div-base-vertical('true');
}
.resize-div-vertical-bottom {
  @include resize-div-base-vertical('false');
}
.resize-div-horizontal-right {
  @include resize-div-base-horizontal('false')
}
.resize-div-horizontal-left {
  @include resize-div-base-horizontal('true')
}
.style-on-resize {
  background-color: $bg-color-scroll;
}
.div-overlay {
  position: absolute;
  inset: 0;
  background-color: transparent;
  pointer-events: auto;
  z-index: 900;
}
/** end resize **/

/** disable class **/
.disable-html {
  background: $color-gray;
  opacity: 0.5;
  cursor: auto !important;
  pointer-events: none !important;
}
/** end diable class **/
