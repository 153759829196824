@use '@angular/material' as mat;
// @import '@angular/material/theming';
@import './custom-colors.scss';
@import './custom-font.scss';

//@include mat-core();
// @include mat.legacy-core();

@include mat.core();

$synergis-app-primary: mat.define-palette($synergis-color-dark);
$synergis-app-accent: mat.define-palette($synergis-color-secondary);
$synergis-app-warn: mat.define-palette(mat.$red-palette);

$synergis-dark-primary: mat.define-palette($synergis-color-dark);
$synergis-dark-accent: mat.define-palette($new-background-color);

$synergis-app-theme: mat.define-light-theme((
    color: (
        primary: $synergis-app-primary,
        accent: $synergis-app-accent,
        warn: $synergis-app-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
));
$synergis-app-custom-theme: mat.define-light-theme((
    color: (
        primary: $synergis-dark-primary,
        accent: $synergis-dark-accent,
        warn: $synergis-app-warn,
    )
));

$new-app-primary: mat.define-palette($synergis-color-primary);
$new-app-primary-theme: mat.define-light-theme((
    color: (
        primary: $new-app-primary,
        accent: $new-app-primary,
        warn: $synergis-app-warn,
    )
));

$new-dark: mat.define-palette($new-dark-color);
$new-dark-theme: mat.define-light-theme((
    color: (
        primary: $new-dark,
        accent: $new-dark,
        warn: $synergis-app-warn,
    )
));

// @include angular-material-theme($synergis-app-theme);
// @include angular-material-typography($custom-typography);
@include mat.all-component-themes($synergis-app-theme);
// @include mat.core-theme($synergis-app-theme);
@include mat.typography-hierarchy($custom-typography);

@include mat.tabs-color($synergis-app-custom-theme);
@include mat.button-color($synergis-app-custom-theme);
@include mat.slider-color($synergis-app-custom-theme);
@include mat.radio-color($new-dark-theme);
@include mat.slide-toggle-color($new-dark-theme);

// $mat-color-primary: mat-color($synergis-app-primary);
// $mat-color-accent: mat-color($synergis-app-accent);
// $mat-color-warn: mat-color($synergis-app-warn);

$mat-color-primary: mat.get-color-from-palette($synergis-app-primary);
$mat-color-accent: mat.get-color-from-palette($synergis-app-accent);
$mat-color-warn: mat.get-color-from-palette($synergis-app-warn);

// For top toolbar
.top-panel {
    @include mat.button-color($new-app-primary-theme);
}

// define class color
.color-primary {
    color: $mat-color-primary !important;
}
.color-accent {
    color: $mat-color-accent !important;
}
.color-warn {
    color: $mat-color-warn !important;
}

// define class background-color
.bg-color-primary {
    background-color: $mat-color-primary !important;
}
.bg-color-accent {
    background-color: $mat-color-accent !important;
}
.bg-color-warn {
    background-color: $mat-color-warn !important;
}
