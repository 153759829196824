@font-face {
  font-family: "Zapf Dingbats";
  src: url("../assets/font/ZapfDingbats/ZapfDingbats.ttf");
}
@font-face {
  font-family: "Avenir";
  src: url("../assets/font/avenirroman/Avenir-Roman.otf") format("opentype");
}
@font-face {
  font-family: "arial";
  src: url("../assets/font/arial/arial.ttf");
}
@font-face {
  font-family: "arial";
  src: url("../assets/font/arial/arialbd.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "arial";
  src: url("../assets/font/arial/ariali.ttf");
  font-style: italic;
}
@font-face {
  font-family: "arial";
  src: url("../assets/font/arial/arialbi.ttf");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "couriernew";
  src: url("../assets/font/couriernew/couriernew.ttf");
}
@font-face {
  font-family: "couriernew";
  src: url("../assets/font/couriernew/couriernewbd.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "couriernew";
  src: url("../assets/font/couriernew/couriernewi.ttf");
  font-style: italic;
}
@font-face {
  font-family: "couriernew";
  src: url("../assets/font/couriernew/couriernewbi.ttf");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "palatino";
  src: url("../assets/font/palatino/palatino.ttf");
}
@font-face {
  font-family: "palatino";
  src: url("../assets/font/palatino/palatinobd.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "palatino";
  src: url("../assets/font/palatino/palatinoi.ttf");
  font-style: italic;
}
@font-face {
  font-family: "palatino";
  src: url("../assets/font/palatino/palatinobi.ttf");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "timesnewroman";
  src: url("../assets/font/timesnewroman/timesnewroman.ttf");
}
@font-face {
  font-family: "timesnewroman";
  src: url("../assets/font/timesnewroman/timesnewromanbd.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "timesnewroman";
  src: url("../assets/font/timesnewroman/timesnewromani.ttf");
  font-style: italic;
}
@font-face {
  font-family: "timesnewroman";
  src: url("../assets/font/timesnewroman/timesnewromanbi.ttf");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "trebuchet";
  src: url("../assets/font/trebuchet/trebuchet.ttf");
}
@font-face {
  font-family: "trebuchet";
  src: url("../assets/font/trebuchet/trebuchetbd.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "trebuchet";
  src: url("../assets/font/trebuchet/trebucheti.ttf");
  font-style: italic;
}
@font-face {
  font-family: "trebuchet";
  src: url("../assets/font/trebuchet/trebuchetbi.ttf");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "verdana";
  src: url("../assets/font/verdana/verdana.ttf");
}
@font-face {
  font-family: "verdana";
  src: url("../assets/font/verdana/verdanabd.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "verdana";
  src: url("../assets/font/verdana/verdanai.ttf");
  font-style: italic;
}
@font-face {
  font-family: "verdana";
  src: url("../assets/font/verdana/verdanabi.ttf");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "scriptmtbold";
  src: url("../assets/font/scriptmtbold/ScriptMTBold.ttf");
}
