@import './define-color.scss';

$synergis-color-primary: (
    50 : #e6ecf3,
    100 : #c1d0e1,
    200 : #98b1cd,
    300 : #6f91b9,
    400 : #507aaa,
    500 : $new-color-primary,
    600 : #2c5a93,
    700 : #255089,
    800 : #1f467f,
    900 : #13346d,
    A100 : #a4c2ff,
    A200 : #71a0ff,
    A400 : #3e7eff,
    A700 : #256cff,
    contrast: (
        50 : $color-dark,
        100 : $color-dark,
        200 : $color-dark,
        300 : $color-dark,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : $color-dark,
        A200 : $color-dark,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

/** secondary color => 500 : #56a97a */
$synergis-color-secondary: (
    50 : #ebf5ef,
    100 : #cce5d7,
    200 : #abd4bd,
    300 : #89c3a2,
    400 : #6fb68e,
    500 : $color-accent,
    600 : #4fa272,
    700 : #459867,
    800 : #3c8f5d,
    900 : #2b7e4a,
    A100 : #c4ffd9,
    A200 : #91ffb8,
    A400 : #5eff96,
    A700 : #45ff86,
    contrast: (
        50 : $color-dark,
        100 : $color-dark,
        200 : $color-dark,
        300 : $color-dark,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : $color-dark,
        A200 : $color-dark,
        A400 : $color-dark,
        A700 : $color-dark,
    )
);

$synergis-color-dark: (
    50 : #e2e4e7,
    100 : #b6bbc3,
    200 : #868e9b,
    300 : #566072,
    400 : #313e54,
    500 : $color-dark,
    600 : #0b1930,
    700 : #091429,
    800 : #071122,
    900 : #030916,
    A100 : #5684ff,
    A200 : #235fff,
    A400 : #0041ef,
    A700 : #003ad5,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

/** New color **/
$new-dark-color: (
    50 : #e5e5e5,
    100 : #bebebe,
    200 : #939393,
    300 : #676767,
    400 : #474747,
    500 : #262626,
    600 : #222222,
    700 : #1c1c1c,
    800 : #171717,
    900 : #0d0d0d,
    A100 : #eb6969,
    A200 : #e53c3c,
    A400 : #ee0000,
    A700 : #d40000,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

/* background color  #f1f1f1 */
$new-background-color: (
    50 : #fdfdfd,
    100 : #fbfbfb,
    200 : #f8f8f8,
    300 : #f5f5f5,
    400 : #f3f3f3,
    500 : #f1f1f1,
    600 : #efefef,
    700 : #ededed,
    800 : #ebebeb,
    900 : #e7e7e7,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


