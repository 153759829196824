/* You can add global styles to this file, and also import other style files */
@import "./node_modules/bootstrap/scss/bootstrap-reboot";
@import "./custom-theme/custom-style-material.scss";
@import "./custom-theme/rtf-style/rtf-style.scss";
@import "shared-styles";
@import "./custom-theme/custom-style.scss";
@import "../node_modules/quill//dist/quill.core.css";
@import "../node_modules/quill//dist/quill.snow.css";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  overflow: hidden;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
a:focus,
button:focus,
input:focus,
textarea:focus {
  outline: none;
}

@mixin flex-center {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin custom-button {
  @include flex-center();
  padding: 0px !important;
  min-width: 0px !important;
  width: $dim-button-panel;
  height: $dim-button-panel;
  border-radius: $radius-button !important;
}

/** Custom Material & Global css **/
.custom-button {
  @include custom-button();
}
/* right panel */

.group-action-row {
  @include flex-col();
  justify-content: center;
  align-items: center;
  background-color: inherit;
  button {
    //margin-right: 16px;
    padding: 0px !important;
    min-width: 0px !important;
    width: $dim-button-panel;
    height: $dim-button-panel;
    background-color: $color-bg-button;
    border-radius: $radius-button !important;
  }
}
/** svg icon **/
.button-svg-icon {
  @include flex-center();
}
.mat-icon-svg {
  @include flex-center();
}
.active-more-icon,
.active-svg-icon {
  svg {
    path {
      fill: $color-primary;
    }
  }
}

/*end */

custom-button-toggle {
  button {
    @include custom-button();
  }
}

@mixin button-icon {
  width: 32px;
  height: 32px;
  border-radius: 10px !important;
}

/* End Custom Material */

/** Active **/
.btn-group-active {
  background-color: $color-primary !important;
  color: $color-bg;
}
.custom-active {
  color: $color-primary !important;
}
.custom-button-toggle-checked {
  color: $color-primary;
}
.tree-node-active {
  border-radius: 8px;
  padding: 2px 8px;
}
/* end active */

@media print {
  .isPrinting > * {
    display: none !important;
  }
  .isPrinting app-print-layout {
    display: block !important;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.pdf-contain {
  width: 100%;
  height: 100%;
}

/** mat tab **/
.custom-mat-tab {
  width: inherit;
  height: inherit;
  .mat-tab-body-wrapper {
    padding: 10px 0px !important;
    flex: 1 1 auto;
  }
}

.fv__ui-toolbar {
  //  display: none !important;
  border-bottom: none !important;
}

.fv__ui-pdfviewer {
  background-color: white;
  // [24/12/2024][ADV-8006][phuong_td] Hidden scrollbar pdfviewer
  overflow: hidden;
}

// Style for search markup filter dialog
.date-picker-toggle {
  background: $color-btn-select;

  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 22px;
  color: #0d1c36;
  .mat-icon-button {
    padding: 7px !important;
    min-width: 0;
    width: auto !important;
    height: auto !important;
    flex-shrink: 0;
    line-height: 40px;
    border-radius: 50%;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.custom-button-colorpicker {
  height: 30px;
  background-color: $color-bg-button;
  border-radius: 5px !important;
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #0d1c36;
}
.resizable {
  resize: both;
  overflow: scroll;
  border: 1px solid black;
}
#main-viewer {
  .noteTextElement {
    position: absolute;
    width: 290px;
    height: 160px;
    min-width: 290px;
    min-height: 160px;
    max-width: 400px;
    max-height: 400px;
    z-index: 2;
    background: rgba(122, 122, 122, 0.9);
    border-radius: 5px;
    border: 1px solid black;
    pointer-events: auto;
    display: flex;
    flex-direction: row;
    padding: 5px;
    overflow: hidden;
    .div-textarea {
      @include flex-center();
      width: 100%;
      height: 100%;
    }
    .div-button {
      @include flex-col();
      margin: 0px 2px;
    }
  }
  .noteTextElement:after {
    border: solid rgba(224, 24, 24, 0);
    content: " ";
    height: 0;
    left: 10px;
    position: absolute;
    width: 0;
    transform: rotate(0.12turn);
    display: none;
  }
  .noteTextElement:after {
    border-width: 11px;
    border-right-color: black;
    top: -12px;
    left: -12px;
    transform: rotate(0.12turn);
    display: none;
  }
  .noteTextElement textArea {
    width: 100%;
    height: 100%;
    z-index: 2;
    resize: none;
    overflow-y: auto;
    border-radius: 10px;
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(172, 172, 172);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }
  }
  .noteTextElement .noteButton {
    border-radius: 5px !important;
    border: #d9d9d9 !important;
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    text-align: center;
    // position: absolute;
    // left: 210px;
    margin-top: 7px;
    width: 75px;
    height: 30px;
  }

  .textBoxElement {
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(172, 172, 172);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }
    &::placeholder {
      color: inherit;
      opacity: 0.5;
    }
    ::-webkit-input-placeholder {
      color: inherit;
      opacity: 0.5;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: inherit;
      opacity: 0.5;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: inherit;
      opacity: 0.5;
    }
    :-ms-input-placeholder {
      color: inherit;
      opacity: 0.5;
    }
  }
}
.pdf-textbox-temp {
  position: absolute;
  inset: 0;
  pointer-events: auto;
  z-index: 30;
  background: transparent;
  cursor: crosshair;
}
.pdf-textbox-rotation-cursor {
  cursor: url(data:image/png;charset=utf-8;base64,AAACAAEAEBAAAAAAAABoAwAAFgAAACgAAAAQAAAAIAAAAAEAGAAAAAAAQAMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACtbS6tbS6tbS6tbS6tbS6tbS4AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACtbS6tbS6tbS6tbS6tbS6tbS6tbS6tbS4AAAAAAAAAAAAAAAAAAAAAAAAAAACtbS6tbS6tbS6tbS6tbS6tbS6tbS6tbS6tbS6tbS4AAAAAAAAAAAAAAAAAAACtbS6tbS6tbS6tbS4AAAAAAAAAAAAAAACtbS6tbS6tbS6tbS4AAAAAAAAAAACtbS6tbS6tbS6tbS4AAAAAAAAAAAAAAAAAAAAAAACtbS6tbS6tbS6tbS4AAAAAAACtbS6tbS6tbS4AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACtbS6tbS6tbS4AAAAAAACtbS6tbS6tbS4AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACtbS6tbS6tbS4AAAAAAACtbS6tbS6tbS4AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACtbS6tbS6tbS4AAAAAAACtbS6tbS6tbS4AAAAAAAAAAAAAAACtbS4AAAAAAAAAAACtbS6tbS6tbS4AAAAAAACtbS6tbS6tbS6tbS4AAAAAAACtbS6tbS4AAAAAAACtbS6tbS6tbS6tbS4AAAAAAAAAAACtbS6tbS6tbS6tbS6tbS6tbS6tbS4AAACtbS6tbS6tbS6tbS4AAAAAAAAAAAAAAAAAAACtbS6tbS6tbS6tbS6tbS6tbS4AAACtbS6tbS6tbS4AAAAAAAAAAAAAAAAAAAAAAAAAAACtbS6tbS6tbS6tbS6tbS4AAAAAAACtbS4AAAAAAAAAAAAAAAAAAAAAAAAAAACtbS6tbS6tbS6tbS6tbS6tbS4AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD//wAA+B8AAPAPAADgBwAAw8MAAIfhAACP8QAAj/EAAI/xAACPcQAAhmEAAMBDAADgRwAA8G8AAOB/AAD//wAA)
      8 8,
    text !important;
}
.pdf-grabing-cursor {
  cursor: grabbing !important;
}
.cursor-rotate * {
  cursor: url(data:image/png;charset=utf-8;base64,AAACAAEAEBAAAAAAAABoAwAAFgAAACgAAAAQAAAAIAAAAAEAGAAAAAAAQAMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACtbS6tbS6tbS6tbS6tbS6tbS4AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACtbS6tbS6tbS6tbS6tbS6tbS6tbS6tbS4AAAAAAAAAAAAAAAAAAAAAAAAAAACtbS6tbS6tbS6tbS6tbS6tbS6tbS6tbS6tbS6tbS4AAAAAAAAAAAAAAAAAAACtbS6tbS6tbS6tbS4AAAAAAAAAAAAAAACtbS6tbS6tbS6tbS4AAAAAAAAAAACtbS6tbS6tbS6tbS4AAAAAAAAAAAAAAAAAAAAAAACtbS6tbS6tbS6tbS4AAAAAAACtbS6tbS6tbS4AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACtbS6tbS6tbS4AAAAAAACtbS6tbS6tbS4AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACtbS6tbS6tbS4AAAAAAACtbS6tbS6tbS4AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACtbS6tbS6tbS4AAAAAAACtbS6tbS6tbS4AAAAAAAAAAAAAAACtbS4AAAAAAAAAAACtbS6tbS6tbS4AAAAAAACtbS6tbS6tbS6tbS4AAAAAAACtbS6tbS4AAAAAAACtbS6tbS6tbS6tbS4AAAAAAAAAAACtbS6tbS6tbS6tbS6tbS6tbS6tbS4AAACtbS6tbS6tbS6tbS4AAAAAAAAAAAAAAAAAAACtbS6tbS6tbS6tbS6tbS6tbS4AAACtbS6tbS6tbS4AAAAAAAAAAAAAAAAAAAAAAAAAAACtbS6tbS6tbS6tbS6tbS4AAAAAAACtbS4AAAAAAAAAAAAAAAAAAAAAAAAAAACtbS6tbS6tbS6tbS6tbS6tbS4AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD//wAA+B8AAPAPAADgBwAAw8MAAIfhAACP8QAAj/EAAI/xAACPcQAAhmEAAMBDAADgRwAA8G8AAOB/AAD//wAA)
      8 8,
    text !important;
}

.cursor-grab-custom * {
  cursor: grab !important;
}
.cursor-zoom-custom * {
  cursor: zoom-in !important;
}
mat-tooltip-component .mat-tooltip.custom-tooltip {
  word-break: break-all;
  white-space: normal;
}

:host::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
:host::-webkit-scrollbar-thumb {
  background: rgb(172, 172, 172);
  border-radius: 10px;
}
:host::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.remove-color-button-class {
  position: absolute;
  top: -5px;
  right: -5px;
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  background: #fff;
  box-shadow: 1px 1px 5px #333;
}

.remove-color-button-class::before {
  content: "x";
  position: absolute;
  top: -7px;
  left: 2.3px;
  display: inline-block;
  font-size: 10px;
}

.remove-color-button-class-firefox {
  position: absolute;
  top: -5px;
  right: -5px;
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  background: #fff;
  box-shadow: 1px 1px 5px #333;
}
.remove-color-button-class-firefox::before {
  content: "x";
  position: absolute;
  bottom: -5px;
  left: 2.4px;
  display: inline-block;
  font-size: 10px;
}

.no-select-text {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.select-text {
  -webkit-touch-callout: text; /* iOS Safari */
  -webkit-user-select: text; /* Safari */
  -khtml-user-select: text; /* Konqueror HTML */
  -moz-user-select: text; /* Old versions of Firefox */
  -ms-user-select: text; /* Internet Explorer/Edge */
  user-select: text; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.spacer {
  flex: 1 1 auto;
}

.disable-button-mode {
  border: 1px solid #999999 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
}

.disable-text {
  color: #cccccc !important;
}

.waiting-main {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 10000;
  background: transparent;
  text-align: center;
  span {
    position: relative;
    top: 50%;
    padding: 10px;
    border-radius: 5px;
    color: #000;
    background-color: #fff;
    font-size: xx-large;
  }
}

.preserve-whitespace {
  white-space: pre;
}
.container-layer .cdk-virtual-scroll-content-wrapper {
  width: 100%;
}

.limit-text-length {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// [12/12/2024][ADV-7980][phuong_td] Hide information dialog box Measure
.fv__ui-measurement-popup-distance  {
  display: none;
}

// [12/12/2024][ADV-7980][phuong_td] Hide information dialog box annot reply
// .fv__annot-reply-dialog {
//   display: none;
// }

// [12/12/2024][ADV-7980][phuong_td] Class Highlight Foxit search results 
.fv__mark-selection-blank {
  background-color: #ffC90E;// #59f;
  filter: alpha(opacity=30);
  -moz-opacity: .3;
  -khtml-opacity: .3;
  opacity: .3;
  padding: 0;
  position: absolute
}